<template>
  <div>
    <div class="pdt-menu" v-for="child in tree" :key="child.index">
      <template v-if="child.meta && child.meta.menu">
        <el-submenu v-if="hasMenus(child)" :index="child.path">
          <template slot="title">
            <img v-if="child.meta && child.meta.icon" :src="child.meta.icon" />
            <span>{{ child.name }}</span>
          </template>
          <menu-item :tree="child.children"></menu-item>
        </el-submenu>
        <el-menu-item v-else :index="child.path">
          <img v-if="child.meta && child.meta.icon" :src="child.meta.icon" />
          <span>{{ child.name }}</span>
        </el-menu-item>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-item",
  props: ["tree"],
  methods: {
    hasMenus: (menu) => {
      return (
        menu.meta &&
        menu.meta.menu &&
        menu.children &&
        menu.children.some((i) => i.meta && i.meta.menu)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.pdt-menu {
  img {
    margin-right: 14px;
    height: 14px;
    width: 14px;
  }
}

.el-menu--collapse .pdt-menu {
  .el-menu-item {
    text-align: center;
  }

  .el-submenu {
    text-align: center;
    ::v-deep .el-submenu__icon-arrow {
      display: none;
    }
  }

  span {
    display: none;
  }

  img {
    margin-right: 0;
    vertical-align: middle;
  }
}
</style>